@import "../../styles/global.scss";

.submission_form {
}

.submission_form .container_s {
}

.submission_form .input_group {
  margin-bottom: 60px;
}

.submission_form .input_group.theme_button_container {
  display: flex;
  justify-content: center;
}

.submission_form .input_group.theme_button_container .disabled {
  color: $lightgrey;
  border-color: $lightgrey;
}

.submission_form .input_group.theme_button_container .disabled:hover {
  border-width: 1px;
  padding: 10px 20px;
  font-weight: 400;
  cursor: not-allowed;
}

.submission_form .input_group.theme_button_container .disabled:active {
  border-width: 1px;
  padding: 10px 20px;
  font-weight: 400;
  color: $lightgrey;
  border-color: $lightgrey;
}

.submission_form hr {
  max-width: 800px;
  margin: 0 auto 60px auto;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e1e4e9;
  padding: 0;
}
