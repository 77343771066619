@import "../../../styles/global.scss";

.completion_module {
  margin: 50px auto 85px auto;
}

.completion_module h1 {
  margin: 0 0 30px 0;
  font-family: "Bungee";
  font-size: 51px;
  user-select: none;
}

.completion_module h2 {
  margin: 0 0 17px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 700;
  font-size: 25px;
  user-select: none;
}

.completion_module p {
  margin: 13px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  user-select: none;
}

.completion_module p .numbers {
  font-family: "Roboto Mono";
  font-size: 17px;
}

.completion_module .images_loading {
  display: flex;
  flex-wrap: wrap;
}

.completion_module .images_loading div {
  display: flex;
  margin-right: 10px;
}

.completion_module .images_loading div .hide {
  display: none;
}

.green {
  color: $green;
}

.electric {
  color: $electric;
}

.spin {
  animation: loading-spin 2.5s linear infinite;
}

@keyframes loading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
