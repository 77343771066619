@import "../../../styles/global.scss";

.artist_intro {
  width: 100%;
  margin: 50px auto 65px auto;
}

.artist_intro h1 {
  font-family: "Bungee";
  margin: 0 0 5px 0;
  font-size: 51px;
  user-select: none;
}

.artist_intro p {
  font-family: "Vollkorn";
  margin: 0 0 38px 0;
  font-size: 19px;
  user-select: none;
  font-style: italic;
}

.artist_intro ul {
  margin: 0 0 30px 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.artist_intro ul.personal_info {
  display: inherit;
  justify-content: inherit;
}

.artist_intro ul li {
  font-family: "Roboto Mono";
  list-style: none;
  font-size: 13px;
  font-weight: 100;
  font-weight: 400;
}

.artist_intro ul li a {
  text-decoration: none;
  color: $black;
  cursor: pointer;
}

.artist_intro ul li a:after {
  content: " >";
}

.artist_intro ul li a:hover {
  color: #4984ff;
}

.artist_intro ul li.date {
  font-weight: 400;
  user-select: none;
  cursor: default;
  // padding-left: 30px;
}

.artist_intro ul li.missing {
  color: $red;
  cursor: default;
  user-select: none;
}

.artist_intro ul li.link {
  margin-right: 11px;
}

@media only screen and (max-width: 1080px) {
  .artist_intro h1 {
    font-size: calc(35px + ((100vw - 400px) / 45));
  }
}
