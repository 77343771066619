@import "../../../../../styles/global.scss";

.image_menu {
  background: $light;
  width: 345px;
  height: calc(100% - 51px);
  overflow: scroll;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $z-middle;
  margin-top: 1px;
  cursor: default;
  box-shadow: $depth1;
  transition: all 250ms ease;
  height: 100%;
  border-left: solid 1px $border;
}

.image_menu.isCollapsed {
  width: 80px;
  min-width: 80px;
  box-shadow: none;
}

.image_menu .padding {
  padding: 25px;
  transition: all 250ms ease;
}

.image_menu.isCollapsed .padding {
  padding: 25px 0 25px 12px;
}

.image_menu .padding .image_container {
  display: flex;
  background: $light;
  box-shadow: $depth0;
  margin-bottom: 11px;
  max-height: 55px;
  height: 55px;
}

.image_menu .padding .image_container {
  background: $white;
}

.image_menu.isCollapsed .padding .image_container {
  width: 55px;
  max-width: 55px;
}

.image_menu .padding .image_container .image {
  width: 55px;
  min-width: 55px;
  height: 55px;
  min-height: 55px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image_menu.isCollapsed .padding .image_container .image {
}

.image_menu .padding .image_container .image_details {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.image_menu.isCollapsed .padding .image_container .image_details {
  display: none;
}

.image_menu .padding .image_container .image_details p {
  font-family: "Roboto Mono", monospace;
  font-size: 9px;
  font-weight: 400;
  color: $lighttext;
  margin: 0;
  padding: 5px;
}

.image_menu .padding .image_container .image_details .image_options {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 8px 0;
}

.image_menu .padding .image_container .image_details .image_options svg {
  cursor: pointer;
  padding-left: 5px;
  width: 20px;
  height: 20px;
  color: $black;
}

.image_menu
  .padding
  .image_container
  .image_details
  .image_options
  svg.do_not_display {
  color: $red;
}

.image_menu
  .padding
  .image_container
  .image_details
  .image_options
  svg.option_move {
  cursor: move;
}

.image_menu .padding .image_container .image_details .image_options svg:hover {
  color: $electric;
}

.image_menu.isCollapsed
  .padding
  .image_container
  .image_details
  .image_options {
  display: none;
}

.image_menu .padding .upload {
  margin-bottom: 11px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.image_menu .padding .upload button {
  overflow: hidden;
  max-height: 55px;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 18px;
  background: none;
  outline: none;
  transition: all 250ms ease, width 0 ease;
}

.image_menu.isCollapsed .padding .upload button {
  width: 55px;
}

.image_menu .padding .upload:hover button {
  border-color: $electric;
  color: $electric;
  padding: 17px;
  border-width: 2px;
  font-weight: 700;
}

.image_menu .padding .upload input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
