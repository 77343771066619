@import "../../../styles/global.scss";

.backdrop {
  height: 100%;
  width: 100%;
  background: $softblue;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-backdrop;
  cursor: pointer;
  transition: all 175ms ease;
  visibility: visible;
}

.backdrop.hide {
  visibility: hidden;
  opacity: 0;
}
