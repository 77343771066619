$red: #d56a6a;
$green: #91cb11;
$white: #ffffff;
$black: #000000;
$electric: #4984ff;
$softblue: #f0f3f6;
$softbluetext: #a2a9b6;
$softblueshadow: #c7c9ca;
$border: #f7f7f7;
$light: #fbfbfb;
$lighttext: #c5c5c5;
$grey: #e9e9e9;
$greytext: #878787;
$lightgrey: #dfdfdf;

$depth-1top: inset 0 10px 20px -10px $softblueshadow;
$depth-1right: inset -10px 0 20px -10px $softblueshadow;
$depth-1left: inset 10px 0 20px -10px $softblueshadow;
$depth0: 0px 1px 8px -2px $softblueshadow;
$depth1: 0px 3px 10px -2px #9f9f9f;

$text-depth1: 0px 3px 10px rgb(159, 159, 159);

$z-module: 10000;
$z-backdrop: 5000;
$z-top: 1000;
$z-front: 750;
$z-middle: 500;
$z-back: 250;
$z-root: 0;

* {
  -webkit-font-smoothing: antialiased;
}

*::selection {
  background: $softblue;
  color: $softbluetext;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: initial;
}

.container_l {
  width: 94%;
  margin: 0 3%;
}

.container_m {
}

.container_s {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto;
}

button:focus {
  outline: none;
}
button.theme_button {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer;
}

button.theme_button:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
}

button.theme_button:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: $electric;
  border-color: $electric;
}
