@import "../../../../styles/global.scss";

.application_view {
  width: calc(100% - 85px);
  margin-left: 85px;
  position: relative;
  height: 100%;
  overflow: scroll;
}

.application_view .app_contents {
  width: calc(100%);
  height: 100%;
}

.application_view .app_contents .links {
  width: 20%;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.application_view .app_contents .links span {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 13px;
  color: $black;
  padding-left: 35px;
  padding-bottom: 14px;
}

.application_view .app_contents .info {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.application_view .app_contents .button_bar {
  position: sticky;
  display: flex;
  justify-content: center;
  width: calc(100%);
  bottom: 0;
  background: $white;
  padding: 15px 0;
  border-top: solid 1px $softblueshadow;
}

.application_view .app_contents .button_bar button {
  margin: 0 7px;
}
