@import "../../../../styles/global.scss";

.editContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 3px;

  border: solid 1px $electric;
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 11px;
}

.editContainer .options {
  width: 100%;
  display: flex;
  align-items: center;
}

.editContainer .options div {
  flex-basis: 0;
  margin-bottom: 0;
}

.editContainer .options div:first-child {
  flex-basis: auto;
  margin-right: 11px;
}

.editContainer .options div:last-child div input {
  max-width: 75px;
}

.editContainer .options button {
  height: 100%;
  margin: 11px auto auto 11px;
}
