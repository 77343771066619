@import "../../../../styles/global.scss";

.signin {
  height: 100vh;
  width: 100vw;
}

.signin .form_element {
  max-width: 500px;
  width: 100%;
  padding: 40px;
  background: aliceblue;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}
