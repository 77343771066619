@import "../../../styles/global.scss";

.link {
  display: contents;
  text-decoration: none;
}
.post_banner {
  display: inline-block;
  width: 23%;
  margin: 1%;
  box-shadow: 0px 1px 5px -2px #c7c9ca;
  transition: box-shadow 250ms ease;
}

.post_banner:hover {
  box-shadow: 0px 3px 10px -2px #9f9f9f;
}

.post_banner .post_banner_img {
  width: 100%;
  overflow: hidden;
  background: $light;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.post_banner .post_banner_img .img_hover_selectors {
  width: 100%;
  display: flex;
  justify-content: center;
}

.post_banner .post_banner_img .img_hover_selectors .selector {
  padding-bottom: 75%;
  width: 33.33%;
}

.post_banner .post_banner_img .img_hover_selectors .selector:hover {
  // background: red;
  border-bottom: 4px solid #4984ff;
  padding-bottom: calc(75% - 4px);
}

.post_banner_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}

.post_banner_text .name {
  font-family: "Signika";
  font-weight: 600;
  font-size: 16px;
  color: black;
}

.post_banner_text .article_link {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 11px;
  text-decoration: none;
  color: black;
}

.post_banner_text .article_link:hover {
  color: #4984ff;
}

@media only screen and (max-width: 1080px) {
  .post_banner {
    width: 31%;
    margin: 1.166%;
  }
}

@media only screen and (max-width: 750px) {
  .post_banner {
    width: 48%;
    margin: 1%;
  }
}
