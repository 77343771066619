.createContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createContainer .options {
  width: 100%;
  display: flex;
  align-items: center;
}

.createContainer .options div {
  flex-basis: auto;
  margin-right: 11px;
}

.createContainer .options button {
  height: 100%;
  margin: 11px auto auto 11px;
}
