@import "../../../styles/global.scss";

.post_list {
  margin: 1% 3%;
}

.post_list_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 1%;
}

.post_list_header h1 {
  margin: 0;
  font-family: "Signika";
  font-weight: 700;
  font-size: 30px;
  color: #dfdfdf;
  user-select: none;
}

.post_list_header a {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 20px;
  color: #dfdfdf;
  text-decoration: none;
}

.post_list_header a:hover {
  color: #4984ff;
}

.post_list_banners {
  display: flex;
  flex-wrap: wrap;
}

.post_list_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  cursor: pointer;
}

.post_list_footer h1 {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.post_list_footer:hover {
  h1 {
    color: #4984ff;
  }
}

@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 750px) {
}
