@import "../../../../styles/global.scss";

.queue_card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $light;
  padding: 11px 15px;
  box-shadow: $depth0;
  margin-bottom: 11px;
  text-decoration: none;
  width: calc(100% - (15px * 2));
  height: 45px;
}

.queue_card:hover {
  background: $white;
}

.queue_card.isCollapsed {
  width: 70%;
  height: 45px;
}

.queue_card.isCollapsed.active {
  width: 80%;
}

.queue_card.isCollapsed h1 {
  font-family: "Signika";
  font-size: 18px;
  font-weight: 600;
  color: $lighttext;
  text-align: center;
}

.queue_card.isCollapsed.active h1 {
  color: $black;
}

.queue_card div {
  overflow: hidden;
  white-space: nowrap;
}

.queue_card .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.queue_card .top .name {
  font-family: "Signika";
  font-size: 18px;
  font-weight: 600;
  color: $lighttext;
}

.queue_card .top .date {
  font-family: "Roboto Mono", monospace;
  font-size: 13px;
  color: $lighttext;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 400;
}

.queue_card .bottom .category {
  font-family: "Roboto Mono", monospace;
  font-size: 13px;
  color: $lighttext;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 100;
}

.queue_card.active {
  background: $white;
  box-shadow: $depth1;
}

.queue_card.active .top .name {
  color: $black;
}

.queue_card.active .bottom {
  color: $black;
}
