@import "../../../../styles/global.scss";

.question_container {
  width: 100%;
  display: flex;
  align-items: center;
}

.question_container .question {
  background: white;
  margin-bottom: 11px;
  margin-right: 4px;
  border: solid 1px black;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
}

.question_container svg {
  margin-left: 8px;
  margin-bottom: 15px;
  cursor: pointer;
}

.question_container svg.save {
  color: $electric;
}

.question_container svg:hover {
  color: $electric;
}

.question_container svg.save:hover {
  //   color: $green;
}

.question_container .question p {
  font-family: "Vollkorn";
  font-weight: 400;
  font-size: 19px;
  margin: 0;
}

.question_container .question p.subtext {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 11px;
  color: $black;
  margin-top: 5px;
}
