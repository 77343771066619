@import "../../../styles/global.scss";

.featured_bar {
  display: flex;
  flex-direction: row;
}

.featured_bar .featured_text {
  z-index: $z-back;
  order: 0;
  color: $black;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.featured_bar .featured_text .text_padding {
  padding: 4% 13% 4% 16%;
  width: 100%;
  max-width: 400px;
  display: table-cell;
}

.featured_bar .featured_text .text_padding span.date {
  margin-bottom: 12px;
  font-family: "Roboto Mono";
  list-style: none;
  font-size: 13px;
  font-weight: 300;
  color: black;
  display: inline-block;
}

.featured_bar .featured_text .text_padding h1 {
  font-family: "Bungee";
  user-select: none;
  margin: 0 0 12px;
  font-size: calc(35px + ((100vw - 1081px) / 45));
  cursor: pointer;
}

.featured_bar .featured_text .text_padding p {
  font-family: "Vollkorn";
  margin: 0 0 36px 0;
  font-size: 19px;
  user-select: none;
}

.featured_bar .featured_text .text_padding ul {
  margin: 0 0 19px 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.featured_bar .featured_text .text_padding ul li {
  font-family: "Roboto Mono";
  list-style: none;
  font-size: 13px;
  font-weight: 100;
  color: $black;
}

.featured_bar .featured_text .text_padding ul li.article_link:hover {
  color: #4984ff;
  cursor: pointer;
}

.featured_bar .featured_text .text_padding ul li.article_link {
  font-weight: 400;
}

.featured_bar .featured_text .text_padding ul.artist_links {
  justify-content: flex-start;
}

.featured_bar .featured_text .text_padding ul.artist_links li {
  margin-right: 6px;
}

.featured_bar .featured_text .text_padding ul.artist_links li svg {
  cursor: pointer;
}

.featured_bar .featured_text .text_padding ul.artist_links li svg:hover {
  color: $electric;
}

.featured_bar .featured_img_slider {
  width: 60%;
  order: 1;
  z-index: $z-back;
  cursor: pointer;
}

@media only screen and (max-width: 1080px) {
  .featured_bar {
    position: relative;
    overflow: hidden;
  }

  .featured_bar .featured_text {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: calc(35px + ((100vw - 400px) / 13.6));
    margin-bottom: calc(30px + ((100vw - 400px) / 34));
    z-index: $z-middle;
    color: $black;
    background-color: $white;
    box-shadow: $depth1;
    max-width: calc(330px + ((100vw - 400px) / 2.62));
    width: 100%;
    justify-content: flex-start;
  }

  .featured_bar .featured_text .text_padding {
    padding: 16px 20px;
  }

  .featured_bar .featured_text .text_padding h1 {
    font-size: calc(23px + ((100vw - 400px) / 30.9));
  }

  .featured_bar .featured_text .text_padding span.date {
    display: none;
  }

  .featured_bar .featured_text .text_padding p {
    display: none;
  }

  .featured_bar .featured_text .text_padding ul {
    margin: 0;
  }

  .featured_bar .featured_text .text_padding ul.artist_links {
    display: none;
  }

  .featured_bar .featured_img_slider {
    width: 100%;
  }
}
