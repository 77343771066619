@import "../../../styles/global.scss";

.text_input {
  width: 100%;
  background: $light;
  margin: auto;
  margin-bottom: 11px;
  display: flex;
  box-shadow: $depth0;
  border: solid 1px $light;
}

.text_input.focus {
  background: $white;
  border-color: $white;
  box-shadow: $depth1;
}

.text_input.error {
  border-color: $red;
}

.text_input .input {
  position: relative;
  flex-grow: 0;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    color: $grey;
  }
}

.text_input .input:first-child {
  flex-grow: 1;
}

.input span {
  position: absolute;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: $lighttext;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  transition: all 250ms ease;
  pointer-events: none;
  user-select: none;
}

.input span.active {
  font-size: 14px;
  top: 7px;
  transform: none;
  transition: all 250ms ease;
}

.text_input.error span {
  color: $red;
}

.input input {
  width: stretch;
  font-family: "Signika";
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  padding: 27px 15px 9px 15px;
  border: 0;
  background: none;
  outline: none;
}

.input .left {
  align-content: left;
}
