@import "../../../styles/global.scss";

.form_settings {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.form_settings .container {
  margin-bottom: 100px;
}

.form_settings .header {
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: $black;
  margin-top: 35px;
  margin-bottom: 7px;
}

.form_settings .subtext {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: $softbluetext;
  margin-top: 0;
}

// .form_settings .question_container {
//   width: 100%;
//   display: flex;
//   align-items: center;
// }

// .form_settings .question_container .question {
//   background: white;
//   margin-bottom: 11px;
//   margin-right: 4px;
//   border: solid 1px black;
//   border-radius: 4px;
//   padding: 15px;
//   width: 100%;
// }

// .form_settings .question_container svg {
//   margin-left: 8px;
//   margin-bottom: 15px;
//   cursor: pointer;
// }

// .form_settings .question_container svg:hover {
//   color: $electric;
// }

// .form_settings .question_container .question p {
//   font-family: "Vollkorn";
//   font-weight: 400;
//   font-size: 19px;
//   margin: 0;
// }

// .form_settings .question_container .question p.subtext {
//   font-family: "Roboto Mono", monospace;
//   font-weight: 400;
//   font-size: 11px;
//   color: $black;
//   margin-top: 5px;
// }

.form_settings .selector {
  display: flex;
}

.form_settings .selector .before_input {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  margin: 0;
  color: $greytext;
  width: 55px;
  padding-top: 18px;
}

.form_settings .selector .total {
  background: white;
  margin: 0 0 11px 11px;
  border: solid 1px black;
  border-radius: 4px;
  padding: 7px 0 0 10px;
  width: 120px;
  font-family: "Signika";
  font-weight: 600;
}

.form_settings .selector .total .label {
  color: $lighttext;
  font-size: 14px;
  margin: 0 0 3px 0;
}

.form_settings .selector .total .amount {
  color: $black;
  font-size: 18px;
  margin: 0;
}
