@import "../../../styles/global.scss";

.question_input {
  max-width: 800px;
  margin: 0 auto 40px auto;
}

.question_input .question {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: $black;
  user-select: none;
}

.question_input .question.error {
  color: $red;
  user-select: none;
}

.question_input .subtext {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  user-select: none;
}

.question_input .input {
  //
}

.question_input .input textarea {
  width: stretch;
  padding: 20px 15px 20px 15px;
  font-family: "Vollkorn";
  font-weight: 400;
  font-size: 18px;
  outline: none;
  resize: none;
  border: solid 1px;
  border-color: $white;
  box-shadow: $depth0;
  background: $white;
}

.question_input .input textarea.empty {
  border-color: #fbfbfb;
  background: #fbfbfb;
}

.question_input .input textarea:focus {
  border-color: $white;
  box-shadow: $depth1;
  background: $white;
}

.question_input .input textarea.error {
  border-color: $red;
  user-select: none;
}

.question_input .input .extra_labels {
}

.question_input .input .extra_labels p.error_message {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 300;
  font-size: 13px;
  color: #a2a9b6;
  float: left;
  user-select: none;
}

.question_input .input .extra_labels p.note_message {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 300;
  font-size: 13px;
  color: #a2a9b6;
  float: left;
  user-select: none;
}

.question_input .input .extra_labels p.limit {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 300;
  font-size: 13px;
  color: #a2a9b6;
  float: right;
  user-select: none;
}

.question_input .input .extra_labels p.error {
  color: $red;
  user-select: none;
}
