@import "../../../styles/global.scss";

.submission_codes {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.submission_codes .container {
  margin-bottom: 100px;
}

.submission_codes .header {
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: $black;
  margin-top: 35px;
  margin-bottom: 7px;
}

.submission_codes .subtext {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: $softbluetext;
  margin-top: 0;
}

.submission_codes .code_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.submission_codes .code_row .code_item {
  // width: 17%;
  display: flex;
  align-items: center;
  margin: 7px 0px;
}

.submission_codes .code_row .code_item:first-child {
  width: 32%;
}

.submission_codes .code_row .code_item h3 {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  color: $greytext;
  margin: 0;
}

.submission_codes .code_row .code_item p {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: $softbluetext;
  margin: 0;
}

.submission_codes .code_row .code_item p.hasUses {
  color: $electric;
  font-weight: 600;
}

.submission_codes .code_row .code_item p span {
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  color: $greytext;
}

.submission_codes .code_row .code_item.options {
  display: flex;
  justify-content: flex-end;
  // padding: 0 10px 8px 0;
}

.submission_codes .code_row .code_item.options svg {
  cursor: pointer;
  // padding-left: 5px;
  width: 20px;
  height: 20px;
  color: $black;
}

.submission_codes .code_row .code_item.options svg:hover {
  color: $electric;
}

.submission_codes .code_row hr {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e1e4e9;
  padding: 0;
}
