@import "../../../styles/global.scss";

$navbar_height: 79px;

.navbar {
  height: calc(100vh - 79px);
  z-index: $z-middle;
}
// .navbar.smaller {
//   height: calc(100vh - 55px);
// }

.navbar .float {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 3%;
  border-bottom: 1px solid #eeeeee;
  background: #ffffff;
  position: fixed;
  top: 0;
  width: 94%;
  z-index: $z-top;
  height: $navbar_height;
  transition: height 150ms ease;
}

.navbar .float.smaller {
  height: 55px;
}

.navbar:before {
  content: "";
  height: $navbar_height;
  width: 100%;
  display: block;
}

.navbar .float:hover {
  height: $navbar_height;
}

.navbar .float .left {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 33.33%;
  justify-content: flex-start;

  // background: yellowgreen;
}

.navbar .float .left a {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  white-space: nowrap;
}

.navbar .float .left section {
  display: flex;
}

.navbar .float .left section .admin_label {
  margin: 0;
  font-family: "signika";
  font-weight: 400;
  font-size: 36px;
  color: $electric;
  padding-left: 20px;
  user-select: none;
  position: relative;
}

.navbar .float .left section .admin_label .notification_icon {
  content: "";
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 7px;
  border: 2px solid white;
  position: absolute;
  top: 19%;
  right: -5%;
  display: none;
}

.navbar .float .left section .admin_label .notification_icon.active {
  display: block;
}

.navbar .float .left section .icon_button {
  background: none;
  border: none;
  margin-bottom: -6px;
  color: $black;
  cursor: pointer;
}

.navbar .float .left section .icon_button:hover {
  color: $electric;
}

.navbar .float .middle {
  display: flex;
  flex-grow: 1;
  width: 33.33%;
  justify-content: center;

  // background: palevioletred;
}
.navbar .float .middle a.social_logo {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  padding: 7.5px;
  display: flex;
  align-items: center;
}

.navbar .float .middle a.social_logo:hover {
  color: #4984ff;
}

.navbar .float .middle a.social_logo svg {
  width: 30px;
  height: 30px;
}

.navbar .float .right {
  display: flex;
  flex-grow: 1;
  width: 33.33%;
  justify-content: flex-end;

  // background: goldenrod;
}
.navbar .float .right a {
  font-family: "signika";
  font-weight: 300;
  font-size: 24px;
  text-decoration: none;
  color: #000000;
  padding-left: 30px;
}

.navbar .float .right a:hover {
  color: #4984ff;
}

.navbar .float .menu_icon {
  margin: 0;
  color: $black;
  padding: 7.5px;
  display: flex;
  cursor: pointer;
  display: none;
}

.navbar .float .menu_icon:hover {
  color: $electric;
}

.navbar .float .menu_icon svg {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 1080px) {
  .navbar .float .middle {
    display: none;
  }

  .navbar .float .right {
    display: none;
  }

  .navbar .float .menu_icon {
    display: initial;
  }
}

@media only screen and (max-width: 750px) {
}
