@import "../../../../styles/global.scss";

.empty_label {
  display: flex;
  padding: 11px 15px;
  margin-bottom: 11px;
  text-decoration: none;
  width: calc(100% - (15px * 2));
  height: 45px;
  border-style: dashed;
  border-width: 3px;
  border-radius: 8px;
  border-color: $lightgrey;
  align-items: center;
  justify-content: center;
  color: $lighttext;
  user-select: none;
}

.empty_label:hover {
  border-left: dashed;
  border-radius: 8px;
  border-color: $lightgrey;
  color: $lighttext;
}

.empty_label.isCollapsed {
  width: 70%;
  height: 45px;
  border-left: none;
  border-radius: 0 8px 8px 0;
}

.empty_label h1 {
  font-family: "Signika";
  font-size: 18px;
  font-weight: 600;
  color: $lighttext;
  text-align: center;
  padding-left: 10px;
}
