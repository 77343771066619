@import "../../../../styles/global.scss";

.review_view {
  width: calc(100% - 80px);
  margin-left: 80px;
  position: relative;
  height: 100%;
}

.review_view .app_contents {
  width: calc(100% - 80px);
  height: 100%;
  overflow: scroll;
}

.review_view .app_contents .questions {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.review_view .app_contents .banner_group {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.review_view .button_bar {
  position: sticky;
  display: flex;
  justify-content: center;
  width: calc(100%);
  bottom: 0;
  background: $white;
  padding: 15px 0;
  border: solid 1px $softblueshadow;
}

.review_view .button_bar button {
  margin: 0 7px;
}
