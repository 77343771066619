@import "../../../styles/global.scss";

.queue {
  width: 30%;
  min-width: 380px;
  height: 100%;
  background: $light;
  overflow: scroll;
  transition: all 250ms ease;
  z-index: $z-front;
  box-shadow: $depth1;
  border-right: solid 1px $border;
}

.queue .padding {
  padding: 25px;
  transition: all 250ms ease;
}

.queue.isFoldable {
  position: absolute;
}

.queue.isCollapsed {
  width: 85px;
  min-width: 85px;
  box-shadow: none;
}

.queue.isCollapsed .padding {
  padding: 25px 25px 25px 0;
}
