@import "../../../styles/global.scss";

.icon {
  pointer-events: none;
}

.image_uploader {
  margin: 50px 0;
  //   background: peru;
  position: relative;
}

.image_uploader .header {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  user-select: none;
}

.image_uploader .header.error {
  color: $red;
  user-select: none;
}

.image_uploader .subtext {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  user-select: none;
}
.image_uploader .upload {
  margin-bottom: 11px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.image_uploader .upload button {
  overflow: hidden;
  max-height: 60px;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 20px 20px;
  background: none;
  outline: none;
}

.image_uploader .upload input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.image_uploader .upload:hover button {
  border-color: $electric;
  color: $electric;
  padding: 19px 19px;
  border-width: 2px;
  font-weight: 700;
}

.image_uploader .images {
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-wrap: wrap;
}

.image_uploader .images .image {
  background: $grey;
  width: 124.166666667px;
  height: 124.166666667px;
  margin-right: 11px;
  margin-bottom: 11px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: $depth0;
}

.image_uploader .images .image:nth-child(6n + 0) {
  margin-right: 0;
}

.image_uploader .images .image button {
  padding: 0;
  width: 24px;
  height: 24px;
  border-width: 0;
  border-radius: 4px;
  position: absolute;
  right: 0;
  margin: 5px;
  cursor: pointer;
}

.image_uploader .images .image button:hover {
  border-color: $electric;
  background: $electric;
  color: $white;
}
