.image_slider {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: calc(350px + ((100vw - 400px) / 7.36));
  display: flex;
  background: #f0f3f6;
}

.image_slider .slider {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
}

.image_slider .slider img {
  display: block;
  height: 100%;
  pointer-events: none;
  padding-right: 3px;
}

.image_slider .slider img:last-child {
  padding-right: 0;
}
