* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__2qvgE {
  width: 94%;
  margin: 0 3%; }

.style_container_s__2hXi0 {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__2clay {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__2clay:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__2clay:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_featured_bar__2HLnJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I {
  z-index: 250;
  -webkit-order: 0;
          order: 0;
  color: #000000;
  width: 40%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: default; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk {
  padding: 4% 13% 4% 16%;
  width: 100%;
  max-width: 400px;
  display: table-cell; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk span.style_date__23NYa {
  margin-bottom: 12px;
  font-family: "Roboto Mono";
  list-style: none;
  font-size: 13px;
  font-weight: 300;
  color: black;
  display: inline-block; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk h1 {
  font-family: "Bungee";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 0 12px;
  font-size: calc(35px + ((100vw - 1081px) / 45));
  cursor: pointer; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk p {
  font-family: "Vollkorn";
  margin: 0 0 36px 0;
  font-size: 19px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul {
  margin: 0 0 19px 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul li {
  font-family: "Roboto Mono";
  list-style: none;
  font-size: 13px;
  font-weight: 100;
  color: #000000; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul li.style_article_link__2wcOH:hover {
  color: #4984ff;
  cursor: pointer; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul li.style_article_link__2wcOH {
  font-weight: 400; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul.style_artist_links__bDMuq {
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul.style_artist_links__bDMuq li {
  margin-right: 6px; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul.style_artist_links__bDMuq li svg {
  cursor: pointer; }

.style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul.style_artist_links__bDMuq li svg:hover {
  color: #4984ff; }

.style_featured_bar__2HLnJ .style_featured_img_slider__3MiKf {
  width: 60%;
  -webkit-order: 1;
          order: 1;
  z-index: 250;
  cursor: pointer; }

@media only screen and (max-width: 1080px) {
  .style_featured_bar__2HLnJ {
    position: relative;
    overflow: hidden; }
  .style_featured_bar__2HLnJ .style_featured_text__1bv9I {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: calc(35px + ((100vw - 400px) / 13.6));
    margin-bottom: calc(30px + ((100vw - 400px) / 34));
    z-index: 500;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 0px 3px 10px -2px #9f9f9f;
    max-width: calc(330px + ((100vw - 400px) / 2.62));
    width: 100%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
  .style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk {
    padding: 16px 20px; }
  .style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk h1 {
    font-size: calc(23px + ((100vw - 400px) / 30.9)); }
  .style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk span.style_date__23NYa {
    display: none; }
  .style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk p {
    display: none; }
  .style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul {
    margin: 0; }
  .style_featured_bar__2HLnJ .style_featured_text__1bv9I .style_text_padding__12wjk ul.style_artist_links__bDMuq {
    display: none; }
  .style_featured_bar__2HLnJ .style_featured_img_slider__3MiKf {
    width: 100%; } }

.style_image_slider__F1jmN {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: calc(350px + ((100vw - 400px) / 7.36));
  display: -webkit-flex;
  display: flex;
  background: #f0f3f6; }

.style_image_slider__F1jmN .style_slider__Pf78x {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  position: absolute; }

.style_image_slider__F1jmN .style_slider__Pf78x img {
  display: block;
  height: 100%;
  pointer-events: none;
  padding-right: 3px; }

.style_image_slider__F1jmN .style_slider__Pf78x img:last-child {
  padding-right: 0; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__1rmDB {
  width: 94%;
  margin: 0 3%; }

.style_container_s__AcMfL {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__2s73s {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__2s73s:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__2s73s:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_post_list__22OlN {
  margin: 1% 3%; }

.style_post_list_header__266lQ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin: 0 1%; }

.style_post_list_header__266lQ h1 {
  margin: 0;
  font-family: "Signika";
  font-weight: 700;
  font-size: 30px;
  color: #dfdfdf;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_post_list_header__266lQ a {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 20px;
  color: #dfdfdf;
  text-decoration: none; }

.style_post_list_header__266lQ a:hover {
  color: #4984ff; }

.style_post_list_banners__1IeYe {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.style_post_list_footer__364QJ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100px;
  cursor: pointer; }

.style_post_list_footer__364QJ h1 {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 18px;
  color: #000000; }

.style_post_list_footer__364QJ:hover h1 {
  color: #4984ff; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__1Yy3O {
  width: 94%;
  margin: 0 3%; }

.style_container_s__1Zz_S {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__2k4s9 {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__2k4s9:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__2k4s9:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_link__2NMZD {
  display: contents;
  text-decoration: none; }

.style_post_banner__16wX8 {
  display: inline-block;
  width: 23%;
  margin: 1%;
  box-shadow: 0px 1px 5px -2px #c7c9ca;
  transition: box-shadow 250ms ease; }

.style_post_banner__16wX8:hover {
  box-shadow: 0px 3px 10px -2px #9f9f9f; }

.style_post_banner__16wX8 .style_post_banner_img__vQ178 {
  width: 100%;
  overflow: hidden;
  background: #fbfbfb;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.style_post_banner__16wX8 .style_post_banner_img__vQ178 .style_img_hover_selectors__2jO8x {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.style_post_banner__16wX8 .style_post_banner_img__vQ178 .style_img_hover_selectors__2jO8x .style_selector__1ywDB {
  padding-bottom: 75%;
  width: 33.33%; }

.style_post_banner__16wX8 .style_post_banner_img__vQ178 .style_img_hover_selectors__2jO8x .style_selector__1ywDB:hover {
  border-bottom: 4px solid #4984ff;
  padding-bottom: calc(75% - 4px); }

.style_post_banner_text__1sjYG {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 5%; }

.style_post_banner_text__1sjYG .style_name__3aEys {
  font-family: "Signika";
  font-weight: 600;
  font-size: 16px;
  color: black; }

.style_post_banner_text__1sjYG .style_article_link__3A6Kv {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 11px;
  text-decoration: none;
  color: black; }

.style_post_banner_text__1sjYG .style_article_link__3A6Kv:hover {
  color: #4984ff; }

@media only screen and (max-width: 1080px) {
  .style_post_banner__16wX8 {
    width: 31%;
    margin: 1.166%; } }

@media only screen and (max-width: 750px) {
  .style_post_banner__16wX8 {
    width: 48%;
    margin: 1%; } }

.style_footer__vpmvi {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background: #f0f3f6;
  padding: 4.5% 7.93%;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 18px;
  color: #a2a9b6;
  box-shadow: inset 0 10px 20px -10px #c7c9ca; }

.style_footer__vpmvi .style_footer_links__1FAY4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.style_footer__vpmvi .style_footer_links__1FAY4 a {
  text-decoration: none;
  color: #a2a9b6; }

.style_footer__vpmvi .style_footer_links__1FAY4 a:hover {
  color: #4984ff; }

.style_footer__vpmvi .style_footer_message__3W4tS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 42%;
  text-align: center; }

.style_footer__vpmvi .style_footer_message__3W4tS p {
  margin: 0;
  padding-bottom: 5%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_footer__vpmvi .style_footer_message__3W4tS a {
  text-decoration: none;
  color: #a2a9b6; }

.style_footer__vpmvi .style_footer_message__3W4tS a:hover {
  color: #4984ff; }

.style_footer__vpmvi .style_footer_copyright__1c8_i {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_footer__vpmvi .style_footer_copyright__1c8_i .style_unicode__2wyjv {
  font-size: 23px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-right: 3px; }

@media only screen and (max-width: 750px) {
  .style_footer__vpmvi {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 50px; }
  .style_footer__vpmvi .style_footer_links__1FAY4 {
    padding-bottom: 50px;
    -webkit-align-items: center;
            align-items: center; }
  .style_footer__vpmvi .style_footer_message__3W4tS {
    padding-bottom: 50px;
    width: 100%; }
  .style_footer__vpmvi .style_footer_copyright__1c8_i {
    padding-bottom: 50px; } }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__aVMg6 {
  width: 94%;
  margin: 0 3%; }

.style_container_s__1HOzj {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__27vry {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__27vry:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__27vry:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_submission_form__hHwH- .style_input_group__13vnE {
  margin-bottom: 60px; }

.style_submission_form__hHwH- .style_input_group__13vnE.style_theme_button_container__aoJdz {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.style_submission_form__hHwH- .style_input_group__13vnE.style_theme_button_container__aoJdz .style_disabled__1seyM {
  color: #dfdfdf;
  border-color: #dfdfdf; }

.style_submission_form__hHwH- .style_input_group__13vnE.style_theme_button_container__aoJdz .style_disabled__1seyM:hover {
  border-width: 1px;
  padding: 10px 20px;
  font-weight: 400;
  cursor: not-allowed; }

.style_submission_form__hHwH- .style_input_group__13vnE.style_theme_button_container__aoJdz .style_disabled__1seyM:active {
  border-width: 1px;
  padding: 10px 20px;
  font-weight: 400;
  color: #dfdfdf;
  border-color: #dfdfdf; }

.style_submission_form__hHwH- hr {
  max-width: 800px;
  margin: 0 auto 60px auto;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e1e4e9;
  padding: 0; }

* {
  -webkit-font-smoothing: antialiased;
}

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6;
}

*::selection {
  background: #f0f3f6;
  color: #a2a9b6;
}

body {
  margin: 0;
  padding: 0;
}

.container_l {
  width: 94%;
  margin: 0 3%;
}

.container_s {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

button.theme_button {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer;
}

button.theme_button:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
}

button.theme_button:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff;
}
/*# sourceMappingURL=global.css.map */
.style_form_intro__3ZBR8 {
  max-width: 800px;
  margin: 50px auto 85px auto; }

.style_form_intro__3ZBR8 h1 {
  margin: 0 0 30px 0;
  font-family: "Bungee";
  font-size: 51px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_form_intro__3ZBR8 h2 {
  margin: 0 0 17px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 700;
  font-size: 25px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_form_intro__3ZBR8 p {
  margin: 13px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_form_intro__3ZBR8 p .style_form_intro_numbers__11Pbw {
  font-family: "Roboto Mono";
  font-size: 17px; }

.style_form_intro__3ZBR8 p a {
  font-family: "Roboto Mono";
  font-size: 17px;
  color: #878787; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l___DqeF {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3eYiC {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3qW-x {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3qW-x:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3qW-x:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_text_input__1u8Ob {
  width: 100%;
  background: #fbfbfb;
  margin: auto;
  margin-bottom: 11px;
  display: -webkit-flex;
  display: flex;
  box-shadow: 0px 1px 8px -2px #c7c9ca;
  border: solid 1px #fbfbfb; }

.style_text_input__1u8Ob.style_focus__3jnPi {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 3px 10px -2px #9f9f9f; }

.style_text_input__1u8Ob.style_error__3YJ68 {
  border-color: #d56a6a; }

.style_text_input__1u8Ob .style_input__pk-Zo {
  position: relative;
  -webkit-flex-grow: 0;
          flex-grow: 0; }
  .style_text_input__1u8Ob .style_input__pk-Zo input[type="number"]::-webkit-inner-spin-button,
  .style_text_input__1u8Ob .style_input__pk-Zo input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .style_text_input__1u8Ob .style_input__pk-Zo ::-webkit-input-placeholder {
    color: #e9e9e9; }
  .style_text_input__1u8Ob .style_input__pk-Zo ::-ms-input-placeholder {
    color: #e9e9e9; }
  .style_text_input__1u8Ob .style_input__pk-Zo ::placeholder {
    color: #e9e9e9; }

.style_text_input__1u8Ob .style_input__pk-Zo:first-child {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.style_input__pk-Zo span {
  position: absolute;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: #c5c5c5;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 15px;
  transition: all 250ms ease;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_input__pk-Zo span.style_active__1wMe4 {
  font-size: 14px;
  top: 7px;
  -webkit-transform: none;
          transform: none;
  transition: all 250ms ease; }

.style_text_input__1u8Ob.style_error__3YJ68 span {
  color: #d56a6a; }

.style_input__pk-Zo input {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  font-family: "Signika";
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  padding: 27px 15px 9px 15px;
  border: 0;
  background: none;
  outline: none; }

.style_input__pk-Zo .style_left__3Tujj {
  -webkit-align-content: left;
          align-content: left; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__zWPrx {
  width: 94%;
  margin: 0 3%; }

.style_container_s__31-Ch {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__2B4nS {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__2B4nS:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__2B4nS:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_icon__f5-bM {
  pointer-events: none; }

.style_image_uploader__1IGJa {
  margin: 50px 0;
  position: relative; }

.style_image_uploader__1IGJa .style_header__3zb0m {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_image_uploader__1IGJa .style_header__3zb0m.style_error__2m2-h {
  color: #d56a6a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_image_uploader__1IGJa .style_subtext__2JxdF {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_image_uploader__1IGJa .style_upload__gMLhM {
  margin-bottom: 11px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%; }

.style_image_uploader__1IGJa .style_upload__gMLhM button {
  overflow: hidden;
  max-height: 60px;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 20px 20px;
  background: none;
  outline: none; }

.style_image_uploader__1IGJa .style_upload__gMLhM input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%; }

.style_image_uploader__1IGJa .style_upload__gMLhM:hover button {
  border-color: #4984ff;
  color: #4984ff;
  padding: 19px 19px;
  border-width: 2px;
  font-weight: 700; }

.style_image_uploader__1IGJa .style_images__Jk0sD {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: relative;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.style_image_uploader__1IGJa .style_images__Jk0sD .style_image__1DgUI {
  background: #e9e9e9;
  width: 124.166666667px;
  height: 124.166666667px;
  margin-right: 11px;
  margin-bottom: 11px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 1px 8px -2px #c7c9ca; }

.style_image_uploader__1IGJa .style_images__Jk0sD .style_image__1DgUI:nth-child(6n + 0) {
  margin-right: 0; }

.style_image_uploader__1IGJa .style_images__Jk0sD .style_image__1DgUI button {
  padding: 0;
  width: 24px;
  height: 24px;
  border-width: 0;
  border-radius: 4px;
  position: absolute;
  right: 0;
  margin: 5px;
  cursor: pointer; }

.style_image_uploader__1IGJa .style_images__Jk0sD .style_image__1DgUI button:hover {
  border-color: #4984ff;
  background: #4984ff;
  color: #ffffff; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__39dEK {
  width: 94%;
  margin: 0 3%; }

.style_container_s__hPtOG {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__12w9l {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__12w9l:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__12w9l:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_question_input__284QB {
  max-width: 800px;
  margin: 0 auto 40px auto; }

.style_question_input__284QB .style_question__22f9M {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_question_input__284QB .style_question__22f9M.style_error__wOHAd {
  color: #d56a6a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_question_input__284QB .style_subtext__3RntN {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_question_input__284QB .style_input__37RVG textarea {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  padding: 20px 15px 20px 15px;
  font-family: "Vollkorn";
  font-weight: 400;
  font-size: 18px;
  outline: none;
  resize: none;
  border: solid 1px;
  border-color: #ffffff;
  box-shadow: 0px 1px 8px -2px #c7c9ca;
  background: #ffffff; }

.style_question_input__284QB .style_input__37RVG textarea.style_empty__2Hkhe {
  border-color: #fbfbfb;
  background: #fbfbfb; }

.style_question_input__284QB .style_input__37RVG textarea:focus {
  border-color: #ffffff;
  box-shadow: 0px 3px 10px -2px #9f9f9f;
  background: #ffffff; }

.style_question_input__284QB .style_input__37RVG textarea.style_error__wOHAd {
  border-color: #d56a6a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_question_input__284QB .style_input__37RVG .style_extra_labels__2lKvN p.style_error_message__1fUuU {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 300;
  font-size: 13px;
  color: #a2a9b6;
  float: left;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_question_input__284QB .style_input__37RVG .style_extra_labels__2lKvN p.style_note_message__3E28Z {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 300;
  font-size: 13px;
  color: #a2a9b6;
  float: left;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_question_input__284QB .style_input__37RVG .style_extra_labels__2lKvN p.style_limit__1YFxG {
  margin: 0;
  font-family: "Roboto Mono";
  font-weight: 300;
  font-size: 13px;
  color: #a2a9b6;
  float: right;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_question_input__284QB .style_input__37RVG .style_extra_labels__2lKvN p.style_error__wOHAd {
  color: #d56a6a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__Pk5Xe {
  width: 94%;
  margin: 0 3%; }

.style_container_s__1AkfG {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3sHlf {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3sHlf:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3sHlf:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_card_input__3J5wP {
  margin-bottom: 60px; }

.style_card_input__3J5wP .style_header__1VFWz {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.style_card_input__3J5wP .style_header__1VFWz svg.style_green__1PiC7 {
  color: #91cb11;
  margin-left: 7px; }

.style_card_input__3J5wP .style_header__1VFWz.style_error__1kFKc {
  color: #d56a6a; }

.style_card_input__3J5wP .style_subtext__11Ud- {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6; }

.style_card_input__3J5wP .style_card_group__3UKhR {
  background: #fbfbfb;
  margin-bottom: 11px;
  box-shadow: 0px 1px 8px -2px #c7c9ca;
  border: solid 1px #fbfbfb;
  padding: 27px 15px 9px;
  padding: 18px 15px; }

.style_card_input__3J5wP .style_card_group__3UKhR.style_fade__3fkpW {
  opacity: 0.25; }

.style_card_input__3J5wP .style_card_group__3UKhR > .style_InputElement__2F2pi {
  background: red; }

.style_card_input__3J5wP .style_card_group--focus__1f-u1 {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 3px 10px -2px #9f9f9f; }

.style_card_input__3J5wP .style_card_group--invalid__2RLvO {
  border-color: #d56a6a; }

.style_card_input__3J5wP .style_donate_input__1o803 {
  display: -webkit-flex;
  display: flex; }

.style_card_input__3J5wP .style_donate_input__1o803.style_hide__-lSxV {
  display: none; }

.style_card_input__3J5wP .style_donate_input__1o803 .style_before_input__m6i4T {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  margin: 0;
  color: #878787;
  width: 55px;
  padding-top: 18px; }

.style_card_input__3J5wP .style_donate_input__1o803 .style_total__3BB6O {
  background: white;
  margin: 0 0 11px 11px;
  border: solid 1px black;
  border-radius: 4px;
  padding: 7px 0 0 10px;
  width: 120px;
  font-family: "Signika";
  font-weight: 600; }

.style_card_input__3J5wP .style_donate_input__1o803 .style_total__3BB6O .style_label__2P7BO {
  color: #c5c5c5;
  font-size: 14px;
  margin: 0 0 3px 0; }

.style_card_input__3J5wP .style_donate_input__1o803 .style_total__3BB6O .style_amount__1FQeb {
  color: #000000;
  font-size: 18px;
  margin: 0; }

.style_card_input__3J5wP .style_links__3qJSM {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 71px;
  -webkit-align-items: center;
          align-items: center; }

.style_card_input__3J5wP .style_links__3qJSM div.style_left__1FFwX .style_hide__-lSxV {
  display: none; }

.style_card_input__3J5wP .style_links__3qJSM div.style_left__1FFwX .style_label__2P7BO {
  font-family: "Signika";
  font-size: 19px;
  font-weight: 300;
  color: #000000;
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default; }

.style_card_input__3J5wP .style_links__3qJSM div.style_right__3OjKz div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }

.style_card_input__3J5wP .style_links__3qJSM div.style_right__3OjKz div svg {
  margin-bottom: 11px;
  margin-right: 11px; }

.style_card_input__3J5wP .style_links__3qJSM div.style_right__3OjKz div svg.style_green__1PiC7 {
  color: #91cb11; }

.style_card_input__3J5wP .style_links__3qJSM div.style_right__3OjKz div svg.style_red__qLUxD {
  color: #d56a6a; }

.style_card_input__3J5wP .style_links__3qJSM div.style_right__3OjKz div div {
  min-width: 275px;
  width: 275px; }

.style_card_input__3J5wP .style_links__3qJSM div.style_right__3OjKz .style_hide__-lSxV {
  display: none; }

.style_card_input__3J5wP .style_links__3qJSM div button {
  font-family: "Signika";
  font-size: 19px;
  font-weight: 300;
  color: #878787;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }

.style_card_input__3J5wP .style_links__3qJSM div button:hover {
  color: #000000; }

.style_card_input__3J5wP .style_links__3qJSM div button:active {
  color: #4984ff; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__2X-BZ {
  width: 94%;
  margin: 0 3%; }

.style_container_s__1shfJ {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__1LKL- {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__1LKL-:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__1LKL-:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_completion_module__3yVji {
  margin: 50px auto 85px auto; }

.style_completion_module__3yVji h1 {
  margin: 0 0 30px 0;
  font-family: "Bungee";
  font-size: 51px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_completion_module__3yVji h2 {
  margin: 0 0 17px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 700;
  font-size: 25px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_completion_module__3yVji p {
  margin: 13px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_completion_module__3yVji p .style_numbers__3FHzf {
  font-family: "Roboto Mono";
  font-size: 17px; }

.style_completion_module__3yVji .style_images_loading__N14pE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.style_completion_module__3yVji .style_images_loading__N14pE div {
  display: -webkit-flex;
  display: flex;
  margin-right: 10px; }

.style_completion_module__3yVji .style_images_loading__N14pE div .style_hide__29m3M {
  display: none; }

.style_green__3E7OV {
  color: #91cb11; }

.style_electric__1x0w9 {
  color: #4984ff; }

.style_spin__2EitP {
  -webkit-animation: style_loading-spin__1-fSx 2.5s linear infinite;
          animation: style_loading-spin__1-fSx 2.5s linear infinite; }

@-webkit-keyframes style_loading-spin__1-fSx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes style_loading-spin__1-fSx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__aJeeK {
  width: 94%;
  margin: 0 3%; }

.style_container_s__KaFNc {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__8tzTX {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__8tzTX:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__8tzTX:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_q_and_a__3uagx {
  width: 100%;
  margin-bottom: 40px;
  cursor: default; }

.style_q_and_a__3uagx .style_question__3tLKy {
  font-family: "Signika";
  font-size: 25px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_q_and_a__3uagx .style_question__3tLKy.style_user_select__22Ce5 {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  cursor: text; }

.style_q_and_a__3uagx .style_answer__1Rzdv {
  font-family: "Vollkorn";
  font-size: 19px;
  color: #000000;
  font-weight: 400;
  line-height: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default; }

.style_q_and_a__3uagx .style_answer__1Rzdv.style_user_select__22Ce5 {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  cursor: text; }

.style_q_and_a__3uagx .style_answer__1Rzdv.style_missing__1TcyT {
  color: #d56a6a; }

.style_q_and_a__3uagx .style_options__2eC2S {
  display: -webkit-flex;
  display: flex;
  padding: 10px 0 15px 0;
  border-bottom: solid 1px #c7c9ca;
  -webkit-align-items: center;
          align-items: center; }

/* Customize the label (the container) */
.style_q_and_a__3uagx .style_options__2eC2S label {
  cursor: pointer;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: #000000;
  font-weight: 400;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 20px;
  position: relative;
  padding-left: 30px;
  margin-left: 20px; }

.style_q_and_a__3uagx .style_options__2eC2S label.style_hover__3F5Ck {
  font-weight: 600; }

.style_q_and_a__3uagx .style_options__2eC2S label.style_active__1NRvp {
  color: #4984ff; }

/* Hide the browser's default checkbox */
.style_q_and_a__3uagx .style_options__2eC2S label input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.style_q_and_a__3uagx .style_options__2eC2S label .style_checkmark__9wbZY {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 2px; }

/* On mouse-over */
.style_q_and_a__3uagx .style_options__2eC2S label:hover input ~ .style_checkmark__9wbZY {
  height: 16px;
  width: 16px;
  border-width: 2px; }

/* On mouse-over */
.style_q_and_a__3uagx .style_options__2eC2S label:hover input ~ .style_checkmark__9wbZY:after {
  left: 4px;
  top: 0px; }

/* When the checkbox is checked */
.style_q_and_a__3uagx .style_options__2eC2S label input:checked ~ .style_checkmark__9wbZY {
  background-color: #4984ff;
  border-color: #4984ff; }

/* Create the checkmark (hidden when not checked) */
.style_q_and_a__3uagx .style_options__2eC2S label .style_checkmark__9wbZY:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.style_q_and_a__3uagx .style_options__2eC2S label input:checked ~ .style_checkmark__9wbZY:after {
  display: block; }

/* Style the checkmark */
.style_q_and_a__3uagx .style_options__2eC2S label .style_checkmark__9wbZY:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

@media only screen and (max-width: 1080px) {
  .style_q_and_a__3uagx .style_question__3tLKy {
    font-size: calc(22px + ((100vw - 400px) / 226.67)); }
  .style_q_and_a__3uagx .style_answer__1Rzdv {
    font-size: calc(17px + ((100vw - 400px) / 340));
    line-height: calc(25px + ((100vw - 400px) / 136)); } }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__1PQfn {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3cDRs {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3AQi- {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3AQi-:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3AQi-:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_artist_intro__2fwBu {
  width: 100%;
  margin: 50px auto 65px auto; }

.style_artist_intro__2fwBu h1 {
  font-family: "Bungee";
  margin: 0 0 5px 0;
  font-size: 51px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_artist_intro__2fwBu p {
  font-family: "Vollkorn";
  margin: 0 0 38px 0;
  font-size: 19px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-style: italic; }

.style_artist_intro__2fwBu ul {
  margin: 0 0 30px 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.style_artist_intro__2fwBu ul.style_personal_info__lm4WX {
  display: inherit;
  -webkit-justify-content: inherit;
          justify-content: inherit; }

.style_artist_intro__2fwBu ul li {
  font-family: "Roboto Mono";
  list-style: none;
  font-size: 13px;
  font-weight: 100;
  font-weight: 400; }

.style_artist_intro__2fwBu ul li a {
  text-decoration: none;
  color: #000000;
  cursor: pointer; }

.style_artist_intro__2fwBu ul li a:after {
  content: " >"; }

.style_artist_intro__2fwBu ul li a:hover {
  color: #4984ff; }

.style_artist_intro__2fwBu ul li.style_date__2bYk6 {
  font-weight: 400;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default; }

.style_artist_intro__2fwBu ul li.style_missing__3dmFh {
  color: #d56a6a;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_artist_intro__2fwBu ul li.style_link__2sZUE {
  margin-right: 11px; }

@media only screen and (max-width: 1080px) {
  .style_artist_intro__2fwBu h1 {
    font-size: calc(35px + ((100vw - 400px) / 45)); } }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__QnKty {
  width: 94%;
  margin: 0 3%; }

.style_container_s__2SXZG {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__1_MgI {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__1_MgI:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__1_MgI:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__3ill6 {
  width: 94%;
  margin: 0 3%; }

.style_container_s__1ecpj {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3ma_q {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3ma_q:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3ma_q:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

a {
  text-decoration: none;
  color: #000000; }

a:hover {
  color: #4984ff; }

.style_all_artists__22bi7 {
  min-height: 600px; }

.style_all_artists__22bi7 .style_artist_row__1sl4o {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.style_all_artists__22bi7 .style_artist_row__1sl4o .style_artist_item__1MigU {
  width: 22%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 0; }

.style_all_artists__22bi7 .style_artist_row__1sl4o .style_artist_item__1MigU:first-child {
  width: 34%; }

.style_all_artists__22bi7 .style_artist_row__1sl4o .style_artist_item__1MigU h3 {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  color: #878787;
  margin: 0; }

.style_all_artists__22bi7 .style_artist_row__1sl4o .style_artist_item__1MigU p {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  margin: 0; }

.style_all_artists__22bi7 .style_artist_row__1sl4o .style_artist_item__1MigU p.style_missing__1aTjV {
  color: #e9e9e9; }

.style_all_artists__22bi7 .style_artist_row__1sl4o .style_artist_item__1MigU p span {
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  color: #878787; }

.style_all_artists__22bi7 .style_artist_row__1sl4o hr {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e1e4e9;
  padding: 0; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__3picX {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3amxY {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__u3jWt {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__u3jWt:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__u3jWt:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_signin___FlCo {
  height: 100vh;
  width: 100vw; }

.style_signin___FlCo .style_form_element__2Ehgv {
  max-width: 500px;
  width: 100%;
  padding: 40px;
  background: aliceblue;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__1i15P {
  width: 94%;
  margin: 0 3%; }

.style_container_s__i-5Sq {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__2pLSW {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__2pLSW:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__2pLSW:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_application_view__1lMC4 {
  width: calc(100% - 85px);
  margin-left: 85px;
  position: relative;
  height: 100%;
  overflow: scroll; }

.style_application_view__1lMC4 .style_app_contents__3VVr3 {
  width: calc(100%);
  height: 100%; }

.style_application_view__1lMC4 .style_app_contents__3VVr3 .style_links__1Qyjq {
  width: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 60px; }

.style_application_view__1lMC4 .style_app_contents__3VVr3 .style_links__1Qyjq span {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  padding-left: 35px;
  padding-bottom: 14px; }

.style_application_view__1lMC4 .style_app_contents__3VVr3 .style_info__FF2pE {
  width: 100%;
  max-width: 800px;
  margin: auto; }

.style_application_view__1lMC4 .style_app_contents__3VVr3 .style_button_bar__2Asud {
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: calc(100%);
  bottom: 0;
  background: #ffffff;
  padding: 15px 0;
  border-top: solid 1px #c7c9ca; }

.style_application_view__1lMC4 .style_app_contents__3VVr3 .style_button_bar__2Asud button {
  margin: 0 7px; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__2Ia57 {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3mXN_ {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__1Tex8 {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__1Tex8:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__1Tex8:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_review_view__2luzw {
  width: calc(100% - 80px);
  margin-left: 80px;
  position: relative;
  height: 100%; }

.style_review_view__2luzw .style_app_contents__3QMhJ {
  width: calc(100% - 80px);
  height: 100%;
  overflow: scroll; }

.style_review_view__2luzw .style_app_contents__3QMhJ .style_questions__1EEiP {
  width: 100%;
  max-width: 800px;
  margin: auto; }

.style_review_view__2luzw .style_app_contents__3QMhJ .style_banner_group__3XDy- {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 50px 0; }

.style_review_view__2luzw .style_button_bar__3kIOC {
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: calc(100%);
  bottom: 0;
  background: #ffffff;
  padding: 15px 0;
  border: solid 1px #c7c9ca; }

.style_review_view__2luzw .style_button_bar__3kIOC button {
  margin: 0 7px; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__2B59V {
  width: 94%;
  margin: 0 3%; }

.style_container_s__14cc- {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__FgUQT {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__FgUQT:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__FgUQT:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_image_menu__1T5-W {
  background: #fbfbfb;
  width: 345px;
  height: calc(100% - 51px);
  overflow: scroll;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 500;
  margin-top: 1px;
  cursor: default;
  box-shadow: 0px 3px 10px -2px #9f9f9f;
  transition: all 250ms ease;
  height: 100%;
  border-left: solid 1px #f7f7f7; }

.style_image_menu__1T5-W.style_isCollapsed__1-1M2 {
  width: 80px;
  min-width: 80px;
  box-shadow: none; }

.style_image_menu__1T5-W .style_padding__3j0A7 {
  padding: 25px;
  transition: all 250ms ease; }

.style_image_menu__1T5-W.style_isCollapsed__1-1M2 .style_padding__3j0A7 {
  padding: 25px 0 25px 12px; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 {
  display: -webkit-flex;
  display: flex;
  background: #fbfbfb;
  box-shadow: 0px 1px 8px -2px #c7c9ca;
  margin-bottom: 11px;
  max-height: 55px;
  height: 55px; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 {
  background: #ffffff; }

.style_image_menu__1T5-W.style_isCollapsed__1-1M2 .style_padding__3j0A7 .style_image_container__2jlH7 {
  width: 55px;
  max-width: 55px; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 .style_image__3dTqq {
  width: 55px;
  min-width: 55px;
  height: 55px;
  min-height: 55px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 .style_image_details__3uchc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.style_image_menu__1T5-W.style_isCollapsed__1-1M2 .style_padding__3j0A7 .style_image_container__2jlH7 .style_image_details__3uchc {
  display: none; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 .style_image_details__3uchc p {
  font-family: "Roboto Mono", monospace;
  font-size: 9px;
  font-weight: 400;
  color: #c5c5c5;
  margin: 0;
  padding: 5px; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 .style_image_details__3uchc .style_image_options__jwjEt {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0 10px 8px 0; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 .style_image_details__3uchc .style_image_options__jwjEt svg {
  cursor: pointer;
  padding-left: 5px;
  width: 20px;
  height: 20px;
  color: #000000; }

.style_image_menu__1T5-W
.style_padding__3j0A7
.style_image_container__2jlH7
.style_image_details__3uchc
.style_image_options__jwjEt
svg.style_do_not_display__3k591 {
  color: #d56a6a; }

.style_image_menu__1T5-W
.style_padding__3j0A7
.style_image_container__2jlH7
.style_image_details__3uchc
.style_image_options__jwjEt
svg.style_option_move__35kYM {
  cursor: move; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_image_container__2jlH7 .style_image_details__3uchc .style_image_options__jwjEt svg:hover {
  color: #4984ff; }

.style_image_menu__1T5-W.style_isCollapsed__1-1M2
.style_padding__3j0A7
.style_image_container__2jlH7
.style_image_details__3uchc
.style_image_options__jwjEt {
  display: none; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_upload__21oFq {
  margin-bottom: 11px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_upload__21oFq button {
  overflow: hidden;
  max-height: 55px;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 18px;
  background: none;
  outline: none;
  transition: all 250ms ease, width 0 ease; }

.style_image_menu__1T5-W.style_isCollapsed__1-1M2 .style_padding__3j0A7 .style_upload__21oFq button {
  width: 55px; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_upload__21oFq:hover button {
  border-color: #4984ff;
  color: #4984ff;
  padding: 17px;
  border-width: 2px;
  font-weight: 700; }

.style_image_menu__1T5-W .style_padding__3j0A7 .style_upload__21oFq input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__K1VQ2 {
  width: 94%;
  margin: 0 3%; }

.style_container_s__1vEXr {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__1cfNJ {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__1cfNJ:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__1cfNJ:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_admin_view__spfFT {
  display: -webkit-flex;
  display: flex;
  height: 100%; }

.style_sidebar__1ePCq {
  height: 100%;
  background: #f0f3f6;
  box-shadow: inset -10px 0 20px -10px #c7c9ca;
  width: 15%;
  min-width: 245px; }

.style_sidebar__1ePCq ul {
  margin: 55px 0 0 55px;
  padding: 0; }

.style_sidebar__1ePCq ul li {
  list-style: none;
  margin-bottom: 15px; }

.style_sidebar__1ePCq ul li a {
  text-decoration: none;
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  font-weight: 400;
  color: #a2a9b6;
  width: 100%;
  display: inline-block; }

.style_sidebar__1ePCq ul li a:hover {
  font-weight: 700;
  color: #4984ff; }

.style_sidebar__1ePCq ul li a:hover:after {
  content: " >"; }

.style_sidebar__1ePCq ul li a.style_active__1SsQO {
  font-weight: 700;
  color: #000000; }

.style_sidebar__1ePCq ul li a.style_active__1SsQO:after {
  content: " >"; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__zjOyW {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3WFAV {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3cKWj {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3cKWj:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3cKWj:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_queue__38dUv {
  width: 30%;
  min-width: 380px;
  height: 100%;
  background: #fbfbfb;
  overflow: scroll;
  transition: all 250ms ease;
  z-index: 750;
  box-shadow: 0px 3px 10px -2px #9f9f9f;
  border-right: solid 1px #f7f7f7; }

.style_queue__38dUv .style_padding__2nn9D {
  padding: 25px;
  transition: all 250ms ease; }

.style_queue__38dUv.style_isFoldable__2yK_B {
  position: absolute; }

.style_queue__38dUv.style_isCollapsed__1ANWd {
  width: 85px;
  min-width: 85px;
  box-shadow: none; }

.style_queue__38dUv.style_isCollapsed__1ANWd .style_padding__2nn9D {
  padding: 25px 25px 25px 0; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__TuWOZ {
  width: 94%;
  margin: 0 3%; }

.style_container_s__2uGDo {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__dz-LF {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__dz-LF:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__dz-LF:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_queue_card__3wAGB {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #fbfbfb;
  padding: 11px 15px;
  box-shadow: 0px 1px 8px -2px #c7c9ca;
  margin-bottom: 11px;
  text-decoration: none;
  width: calc(100% - (15px * 2));
  height: 45px; }

.style_queue_card__3wAGB:hover {
  background: #ffffff; }

.style_queue_card__3wAGB.style_isCollapsed__lwZWY {
  width: 70%;
  height: 45px; }

.style_queue_card__3wAGB.style_isCollapsed__lwZWY.style_active__9cOJ8 {
  width: 80%; }

.style_queue_card__3wAGB.style_isCollapsed__lwZWY h1 {
  font-family: "Signika";
  font-size: 18px;
  font-weight: 600;
  color: #c5c5c5;
  text-align: center; }

.style_queue_card__3wAGB.style_isCollapsed__lwZWY.style_active__9cOJ8 h1 {
  color: #000000; }

.style_queue_card__3wAGB div {
  overflow: hidden;
  white-space: nowrap; }

.style_queue_card__3wAGB .style_top__1UEdX {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.style_queue_card__3wAGB .style_top__1UEdX .style_name__3J_uC {
  font-family: "Signika";
  font-size: 18px;
  font-weight: 600;
  color: #c5c5c5; }

.style_queue_card__3wAGB .style_top__1UEdX .style_date__2Xojx {
  font-family: "Roboto Mono", monospace;
  font-size: 13px;
  color: #c5c5c5;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-weight: 400; }

.style_queue_card__3wAGB .style_bottom__3s7C5 .style_category__248ep {
  font-family: "Roboto Mono", monospace;
  font-size: 13px;
  color: #c5c5c5;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-weight: 100; }

.style_queue_card__3wAGB.style_active__9cOJ8 {
  background: #ffffff;
  box-shadow: 0px 3px 10px -2px #9f9f9f; }

.style_queue_card__3wAGB.style_active__9cOJ8 .style_top__1UEdX .style_name__3J_uC {
  color: #000000; }

.style_queue_card__3wAGB.style_active__9cOJ8 .style_bottom__3s7C5 {
  color: #000000; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__2oeW7 {
  width: 94%;
  margin: 0 3%; }

.style_container_s__f6WJC {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__IRBER {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__IRBER:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__IRBER:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_empty_label__1rlXq {
  display: -webkit-flex;
  display: flex;
  padding: 11px 15px;
  margin-bottom: 11px;
  text-decoration: none;
  width: calc(100% - (15px * 2));
  height: 45px;
  border-style: dashed;
  border-width: 3px;
  border-radius: 8px;
  border-color: #dfdfdf;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #c5c5c5;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_empty_label__1rlXq:hover {
  border-left: dashed;
  border-radius: 8px;
  border-color: #dfdfdf;
  color: #c5c5c5; }

.style_empty_label__1rlXq.style_isCollapsed__2Wo3s {
  width: 70%;
  height: 45px;
  border-left: none;
  border-radius: 0 8px 8px 0; }

.style_empty_label__1rlXq h1 {
  font-family: "Signika";
  font-size: 18px;
  font-weight: 600;
  color: #c5c5c5;
  text-align: center;
  padding-left: 10px; }

.style_inbox__3TN66 {
  width: 100%;
  position: relative; }

.style_review__1-l3M {
  width: 100%;
  position: relative; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__3n25b {
  width: 94%;
  margin: 0 3%; }

.style_container_s__MMy9Y {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3TvBk {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3TvBk:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3TvBk:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_form_settings__d1eO6 {
  width: 100%;
  height: 100%;
  overflow: scroll; }

.style_form_settings__d1eO6 .style_container__3PVO4 {
  margin-bottom: 100px; }

.style_form_settings__d1eO6 .style_header__2ZqGj {
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  margin-top: 35px;
  margin-bottom: 7px; }

.style_form_settings__d1eO6 .style_subtext__2xECi {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  margin-top: 0; }

.style_form_settings__d1eO6 .style_selector__3APXd {
  display: -webkit-flex;
  display: flex; }

.style_form_settings__d1eO6 .style_selector__3APXd .style_before_input__3RtW3 {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  margin: 0;
  color: #878787;
  width: 55px;
  padding-top: 18px; }

.style_form_settings__d1eO6 .style_selector__3APXd .style_total__c4x6I {
  background: white;
  margin: 0 0 11px 11px;
  border: solid 1px black;
  border-radius: 4px;
  padding: 7px 0 0 10px;
  width: 120px;
  font-family: "Signika";
  font-weight: 600; }

.style_form_settings__d1eO6 .style_selector__3APXd .style_total__c4x6I .style_label__2qqBQ {
  color: #c5c5c5;
  font-size: 14px;
  margin: 0 0 3px 0; }

.style_form_settings__d1eO6 .style_selector__3APXd .style_total__c4x6I .style_amount__ixAVy {
  color: #000000;
  font-size: 18px;
  margin: 0; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__22X0- {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3hGDc {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__1UQ4c {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__1UQ4c:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__1UQ4c:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_createContainer__3sw02 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%; }

.style_createContainer__3sw02 .style_options__GKyRy {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.style_createContainer__3sw02 .style_options__GKyRy div {
  -webkit-flex-basis: 0;
          flex-basis: 0; }

.style_createContainer__3sw02 .style_options__GKyRy div:first-child {
  -webkit-flex-basis: auto;
          flex-basis: auto;
  margin-right: 11px; }

.style_createContainer__3sw02 .style_options__GKyRy button {
  height: 100%;
  margin: 11px auto auto 11px; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__3CwtB {
  width: 94%;
  margin: 0 3%; }

.style_container_s__yliyW {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3rZ2n {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3rZ2n:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3rZ2n:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_question_container__3AX9j {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.style_question_container__3AX9j .style_question__3Gjli {
  background: white;
  margin-bottom: 11px;
  margin-right: 4px;
  border: solid 1px black;
  border-radius: 4px;
  padding: 15px;
  width: 100%; }

.style_question_container__3AX9j svg {
  margin-left: 8px;
  margin-bottom: 15px;
  cursor: pointer; }

.style_question_container__3AX9j svg.style_save__uvAm0 {
  color: #4984ff; }

.style_question_container__3AX9j svg:hover {
  color: #4984ff; }

.style_question_container__3AX9j .style_question__3Gjli p {
  font-family: "Vollkorn";
  font-weight: 400;
  font-size: 19px;
  margin: 0; }

.style_question_container__3AX9j .style_question__3Gjli p.style_subtext__35-QE {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 11px;
  color: #000000;
  margin-top: 5px; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__F9YtA {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3Owxk {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__2ZEbQ {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__2ZEbQ:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__2ZEbQ:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_editContainer__K70k2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-right: 3px;
  border: solid 1px #4984ff;
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 11px; }

.style_editContainer__K70k2 .style_options__3uNFk {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.style_editContainer__K70k2 .style_options__3uNFk div {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  margin-bottom: 0; }

.style_editContainer__K70k2 .style_options__3uNFk div:first-child {
  -webkit-flex-basis: auto;
          flex-basis: auto;
  margin-right: 11px; }

.style_editContainer__K70k2 .style_options__3uNFk div:last-child div input {
  max-width: 75px; }

.style_editContainer__K70k2 .style_options__3uNFk button {
  height: 100%;
  margin: 11px auto auto 11px; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__3bpxX {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3miX9 {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__1scy3 {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__1scy3:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__1scy3:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_submission_codes__1t931 {
  width: 100%;
  height: 100%;
  overflow: scroll; }

.style_submission_codes__1t931 .style_container__1xroo {
  margin-bottom: 100px; }

.style_submission_codes__1t931 .style_header__1ZDGP {
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  margin-top: 35px;
  margin-bottom: 7px; }

.style_submission_codes__1t931 .style_subtext__2U96m {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  margin-top: 0; }

.style_submission_codes__1t931 .style_code_row__1Km5q {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 7px 0px; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf:first-child {
  width: 32%; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf h3 {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  color: #878787;
  margin: 0; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf p {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
  margin: 0; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf p.style_hasUses__3aaQO {
  color: #4984ff;
  font-weight: 600; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf p span {
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  color: #878787; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf.style_options__2gFsY {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf.style_options__2gFsY svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #000000; }

.style_submission_codes__1t931 .style_code_row__1Km5q .style_code_item__3hRVf.style_options__2gFsY svg:hover {
  color: #4984ff; }

.style_submission_codes__1t931 .style_code_row__1Km5q hr {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e1e4e9;
  padding: 0; }

.style_createContainer__2ZF_q {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.style_createContainer__2ZF_q .style_options__1p3us {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.style_createContainer__2ZF_q .style_options__1p3us div {
  -webkit-flex-basis: auto;
          flex-basis: auto;
  margin-right: 11px; }

.style_createContainer__2ZF_q .style_options__1p3us button {
  height: 100%;
  margin: 11px auto auto 11px; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__Z37Sn {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3kvG2 {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__1t8Je {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__1t8Je:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__1t8Je:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_navbar__2hfTF {
  height: calc(100vh - 79px);
  z-index: 500; }

.style_navbar__2hfTF .style_float__3_6Ln {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0% 3%;
  border-bottom: 1px solid #eeeeee;
  background: #ffffff;
  position: fixed;
  top: 0;
  width: 94%;
  z-index: 1000;
  height: 79px;
  transition: height 150ms ease; }

.style_navbar__2hfTF .style_float__3_6Ln.style_smaller__3SCJO {
  height: 55px; }

.style_navbar__2hfTF:before {
  content: "";
  height: 79px;
  width: 100%;
  display: block; }

.style_navbar__2hfTF .style_float__3_6Ln:hover {
  height: 79px; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 33.33%;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- a {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  white-space: nowrap; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- section {
  display: -webkit-flex;
  display: flex; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- section .style_admin_label__KnJs7 {
  margin: 0;
  font-family: "signika";
  font-weight: 400;
  font-size: 36px;
  color: #4984ff;
  padding-left: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- section .style_admin_label__KnJs7 .style_notification_icon__1zRDU {
  content: "";
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 7px;
  border: 2px solid white;
  position: absolute;
  top: 19%;
  right: -5%;
  display: none; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- section .style_admin_label__KnJs7 .style_notification_icon__1zRDU.style_active__3N6G5 {
  display: block; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- section .style_icon_button__2nmDk {
  background: none;
  border: none;
  margin-bottom: -6px;
  color: #000000;
  cursor: pointer; }

.style_navbar__2hfTF .style_float__3_6Ln .style_left__12V3- section .style_icon_button__2nmDk:hover {
  color: #4984ff; }

.style_navbar__2hfTF .style_float__3_6Ln .style_middle__2vXpO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 33.33%;
  -webkit-justify-content: center;
          justify-content: center; }

.style_navbar__2hfTF .style_float__3_6Ln .style_middle__2vXpO a.style_social_logo__2_yUA {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  padding: 7.5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.style_navbar__2hfTF .style_float__3_6Ln .style_middle__2vXpO a.style_social_logo__2_yUA:hover {
  color: #4984ff; }

.style_navbar__2hfTF .style_float__3_6Ln .style_middle__2vXpO a.style_social_logo__2_yUA svg {
  width: 30px;
  height: 30px; }

.style_navbar__2hfTF .style_float__3_6Ln .style_right__1D7OI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 33.33%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.style_navbar__2hfTF .style_float__3_6Ln .style_right__1D7OI a {
  font-family: "signika";
  font-weight: 300;
  font-size: 24px;
  text-decoration: none;
  color: #000000;
  padding-left: 30px; }

.style_navbar__2hfTF .style_float__3_6Ln .style_right__1D7OI a:hover {
  color: #4984ff; }

.style_navbar__2hfTF .style_float__3_6Ln .style_menu_icon__1v5vV {
  margin: 0;
  color: #000000;
  padding: 7.5px;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  display: none; }

.style_navbar__2hfTF .style_float__3_6Ln .style_menu_icon__1v5vV:hover {
  color: #4984ff; }

.style_navbar__2hfTF .style_float__3_6Ln .style_menu_icon__1v5vV svg {
  width: 30px;
  height: 30px; }

@media only screen and (max-width: 1080px) {
  .style_navbar__2hfTF .style_float__3_6Ln .style_middle__2vXpO {
    display: none; }
  .style_navbar__2hfTF .style_float__3_6Ln .style_right__1D7OI {
    display: none; }
  .style_navbar__2hfTF .style_float__3_6Ln .style_menu_icon__1v5vV {
    display: inline;
    display: initial; } }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__6zJkY {
  width: 94%;
  margin: 0 3%; }

.style_container_s__hAuQw {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__2KsE2 {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__2KsE2:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__2KsE2:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_backdrop__3TYJJ {
  height: 100%;
  width: 100%;
  background: #f0f3f6;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  cursor: pointer;
  transition: all 175ms ease;
  visibility: visible; }

.style_backdrop__3TYJJ.style_hide__UTSPx {
  visibility: hidden;
  opacity: 0; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__3cZnL {
  width: 94%;
  margin: 0 3%; }

.style_container_s__i6YVn {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3qNXc {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3qNXc:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3qNXc:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

* {
  -webkit-font-smoothing: antialiased; }

*::-moz-selection {
  background: #f0f3f6;
  color: #a2a9b6; }

*::selection {
  background: #f0f3f6;
  color: #a2a9b6; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: inline;
  display: initial; }

.style_container_l__3HfwD {
  width: 94%;
  margin: 0 3%; }

.style_container_s__3XQFY {
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto; }

button:focus {
  outline: none; }

button.style_theme_button__3WixS {
  margin: 0;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: black;
  font-weight: 400;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  background: none;
  outline: none;
  cursor: pointer; }

button.style_theme_button__3WixS:hover {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700; }

button.style_theme_button__3WixS:active {
  border-width: 2px;
  padding: 9px 19px;
  font-weight: 700;
  color: #4984ff;
  border-color: #4984ff; }

.style_menu_drawer__2bbgO {
  background: #ffffff;
  box-shadow: 0px 3px 10px -2px #9f9f9f;
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 68px 0 34px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  visibility: visible;
  transition: all 250ms ease; }

.style_menu_drawer__2bbgO.style_hide__1g6hO {
  visibility: hidden;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.style_menu_drawer__2bbgO.style_hide__1g6hO .style_exit_button__2WTcj {
  display: none; }

.style_menu_drawer__2bbgO .style_exit_button__2WTcj {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -45px;
  margin-top: 39.5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 30px;
  width: 30px;
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer; }

.style_menu_drawer__2bbgO .style_exit_button__2WTcj:hover {
  border-color: #4984ff;
  background: #4984ff;
  color: #ffffff; }

.style_menu_drawer__2bbgO .style_exit_button__2WTcj svg {
  width: 30px;
  height: 30px; }

.style_menu_drawer__2bbgO .style_top__1G8oW {
  display: -webkit-flex;
  display: flex;
  margin-top: 17px; }

.style_menu_drawer__2bbgO .style_top__1G8oW a {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  white-space: nowrap; }

.style_menu_drawer__2bbgO .style_top__1G8oW section a.style_admin_label__G3sVw {
  margin: 0;
  font-family: "signika";
  font-weight: 400;
  font-size: 36px;
  color: #4984ff;
  padding-left: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.style_menu_drawer__2bbgO .style_top__1G8oW section .style_icon_button__3ya89 {
  background: none;
  border: none;
  margin-bottom: -6px;
  color: #000000;
  cursor: pointer; }

.style_menu_drawer__2bbgO .style_top__1G8oW section .style_icon_button__3ya89:hover {
  color: #4984ff; }

.style_menu_drawer__2bbgO .style_middle__1DgW7 {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 34px; }

.style_menu_drawer__2bbgO .style_middle__1DgW7 a {
  font-family: "signika";
  font-weight: 300;
  font-size: 24px;
  text-decoration: none;
  color: #000000;
  padding: 4px 0 4px 0; }

.style_menu_drawer__2bbgO .style_middle__1DgW7 a:hover {
  color: #4984ff; }

.style_menu_drawer__2bbgO .style_bottom__DUrHD {
  margin-bottom: 34px;
  display: -webkit-flex;
  display: flex; }

.style_menu_drawer__2bbgO .style_bottom__DUrHD a.style_social_logo__PBynT {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  padding: 7.5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.style_menu_drawer__2bbgO .style_bottom__DUrHD a.style_social_logo__PBynT:hover {
  color: #4984ff; }

.style_menu_drawer__2bbgO .style_bottom__DUrHD a.style_social_logo__PBynT svg {
  width: 30px;
  height: 30px; }

