@import "../../../styles/global.scss";

.admin_view {
  display: flex;
  height: 100%;
}
.sidebar {
  height: 100%;
  background: $softblue;
  box-shadow: $depth-1right;
  width: 15%;
  min-width: 245px;
}

.sidebar ul {
  margin: 55px 0 0 55px;
  padding: 0;
}

.sidebar ul li {
  list-style: none;
  margin-bottom: 15px;
}

.sidebar ul li a {
  text-decoration: none;
  font-family: "Roboto Mono", monospace;
  font-size: 16px;
  font-weight: 400;
  color: $softbluetext;
  width: 100%;
  display: inline-block;
}

.sidebar ul li a:hover {
  font-weight: 700;
  color: $electric;
}
.sidebar ul li a:hover:after {
  content: " >";
}

.sidebar ul li a.active {
  font-weight: 700;
  color: $black;
}
.sidebar ul li a.active:after {
  content: " >";
}
