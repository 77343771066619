@import "../../../styles/global.scss";

.q_and_a {
  width: 100%;
  margin-bottom: 40px;
  cursor: default;
}

.q_and_a .question {
  font-family: "Signika";
  font-size: 25px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: default;
  user-select: none;
}

.q_and_a .question.user_select {
  user-select: text;
  cursor: text;
}

.q_and_a .answer {
  font-family: "Vollkorn";
  font-size: 19px;
  color: #000000;
  font-weight: 400;
  line-height: 30px;
  user-select: none;
  cursor: default;
}
.q_and_a .answer.user_select {
  user-select: text;
  cursor: text;
}

.q_and_a .answer.missing {
  color: $red;
}

.q_and_a .options {
  display: flex;
  padding: 10px 0 15px 0;
  border-bottom: solid 1px $softblueshadow;
  align-items: center;
}

/* Customize the label (the container) */
.q_and_a .options label {
  cursor: pointer;
  font-family: "Roboto Mono";
  font-size: 13px;
  color: #000000;
  font-weight: 400;
  user-select: none; ////////////////////////////////////////
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
  padding-left: 30px;
  margin-left: 20px;
}

.q_and_a .options label.hover {
  font-weight: 600;
}

.q_and_a .options label.active {
  color: $electric;
}

/* Hide the browser's default checkbox */
.q_and_a .options label input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.q_and_a .options label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 2px;
}

/* On mouse-over */
.q_and_a .options label:hover input ~ .checkmark {
  height: 16px;
  width: 16px;
  border-width: 2px;
}

/* On mouse-over */
.q_and_a .options label:hover input ~ .checkmark:after {
  left: 4px;
  top: 0px;
}

/* When the checkbox is checked */
.q_and_a .options label input:checked ~ .checkmark {
  background-color: $electric;
  border-color: $electric;
}

/* Create the checkmark (hidden when not checked) */
.q_and_a .options label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.q_and_a .options label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark */
.q_and_a .options label .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 1080px) {
  .q_and_a .question {
    font-size: calc(22px + ((100vw - 400px) / 226.67));
  }

  .q_and_a .answer {
    font-size: calc(17px + ((100vw - 400px) / 340));
    line-height: calc(25px + ((100vw - 400px) / 136));
  }
}
