.form_intro {
  max-width: 800px;
  margin: 50px auto 85px auto;
}

.form_intro h1 {
  margin: 0 0 30px 0;
  font-family: "Bungee";
  font-size: 51px;
  user-select: none;
}

.form_intro h2 {
  margin: 0 0 17px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 700;
  font-size: 25px;
  user-select: none;
}

.form_intro p {
  margin: 13px 0;
  font-family: "Vollkorn", "serif";
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  user-select: none;
}

.form_intro p .form_intro_numbers {
  font-family: "Roboto Mono";
  font-size: 17px;
}

.form_intro p a {
  font-family: "Roboto Mono";
  font-size: 17px;
  color: #878787;
}
