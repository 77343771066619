@import "../../../styles/global.scss";

.menu_drawer {
  background: $white;
  box-shadow: $depth1;
  z-index: $z-module;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 68px 0 34px;
  transform: translateX(0);
  visibility: visible;
  transition: all 250ms ease;
}

.menu_drawer.hide {
  visibility: hidden;
  transform: translateX(100%);
}

.menu_drawer.hide .exit_button {
  display: none;
}

.menu_drawer .exit_button {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -45px;
  margin-top: 39.5px;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  background: $white;
  border-radius: 6px;
  cursor: pointer;
}

.menu_drawer .exit_button:hover {
  border-color: $electric;
  background: $electric;
  color: $white;
}

.menu_drawer .exit_button svg {
  width: 30px;
  height: 30px;
}

.menu_drawer .top {
  display: flex;
  margin-top: 17px;
}

.menu_drawer .top a {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  white-space: nowrap;
}

.menu_drawer .top section a.admin_label {
  margin: 0;
  font-family: "signika";
  font-weight: 400;
  font-size: 36px;
  color: $electric;
  padding-left: 20px;
  user-select: none;
}

.menu_drawer .top section .icon_button {
  background: none;
  border: none;
  margin-bottom: -6px;
  color: $black;
  cursor: pointer;
}

.menu_drawer .top section .icon_button:hover {
  color: $electric;
}

.menu_drawer .middle {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 34px;
}

.menu_drawer .middle a {
  font-family: "signika";
  font-weight: 300;
  font-size: 24px;
  text-decoration: none;
  color: #000000;
  padding: 4px 0 4px 0;
}

.menu_drawer .middle a:hover {
  color: #4984ff;
}

.menu_drawer .bottom {
  margin-bottom: 34px;
  display: flex;
}

.menu_drawer .bottom a.social_logo {
  margin: 0;
  font-family: "signika";
  font-weight: 700;
  font-size: 36px;
  text-decoration: none;
  color: #000000;
  padding: 7.5px;
  display: flex;
  align-items: center;
}

.menu_drawer .bottom a.social_logo:hover {
  color: #4984ff;
}

.menu_drawer .bottom a.social_logo svg {
  width: 30px;
  height: 30px;
}
