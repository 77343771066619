.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0f3f6;
  padding: 4.5% 7.93%;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 18px;
  color: #a2a9b6;
  box-shadow: inset 0 10px 20px -10px #c7c9ca;
}

.footer .footer_links {
  display: flex;
  flex-direction: column;
}

.footer .footer_links a {
  text-decoration: none;
  color: #a2a9b6;
}

.footer .footer_links a:hover {
  color: #4984ff;
}

.footer .footer_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42%;
  text-align: center;
}

.footer .footer_message p {
  margin: 0;
  padding-bottom: 5%;
  user-select: none;
}

.footer .footer_message a {
  text-decoration: none;
  color: #a2a9b6;
}

.footer .footer_message a:hover {
  color: #4984ff;
}

.footer .footer_copyright {
  user-select: none;
}
.footer .footer_copyright .unicode {
  font-size: 23px;
  user-select: none;
  padding-right: 3px;
}

//////////////

@media only screen and (max-width: 750px) {
  .footer {
    flex-direction: column;
    padding-top: 50px;
  }

  .footer .footer_links {
    padding-bottom: 50px;
    align-items: center;
  }

  .footer .footer_message {
    padding-bottom: 50px;
    width: 100%;
  }

  .footer .footer_copyright {
    padding-bottom: 50px;
  }
}
