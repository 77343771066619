@import "../../styles/global.scss";

a {
  text-decoration: none;
  color: $black;
}

a:hover {
  color: $electric;
}

.all_artists {
  min-height: 600px;
}

.all_artists .artist_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.all_artists .artist_row .artist_item {
  width: 22%;
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.all_artists .artist_row .artist_item:first-child {
  width: 34%;
}

.all_artists .artist_row .artist_item h3 {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  color: $greytext;
  margin: 0;
}

.all_artists .artist_row .artist_item p {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  color: $softbluetext;
  margin: 0;
}

.all_artists .artist_row .artist_item p.missing {
  color: $grey;
}

.all_artists .artist_row .artist_item p span {
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  color: $greytext;
}

.all_artists .artist_row hr {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e1e4e9;
  padding: 0;
}
