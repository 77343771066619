@import "../../styles/global.scss";

.card_input {
  margin-bottom: 60px;
}

.card_input .header {
  margin: 0;
  margin-bottom: 7px;
  font-family: "Signika";
  font-weight: 600;
  font-size: 25px;
  color: $black;
  display: flex;
  align-items: center;
}

.card_input .header svg.green {
  color: $green;
  margin-left: 7px;
}

.card_input .header.error {
  color: $red;
}

.card_input .subtext {
  margin: 0;
  margin-bottom: 20px;
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 15px;
  color: #a2a9b6;
}

.card_input .card_group {
  background: $light;
  margin-bottom: 11px;
  box-shadow: $depth0;
  border: solid 1px $light;
  padding: 27px 15px 9px;
  padding: 18px 15px;
}

.card_input .card_group.fade {
  opacity: 0.25;
}

.card_input .card_group > .InputElement {
  background: red;
}

.card_input .card_group--focus {
  background: $white;
  border-color: $white;
  box-shadow: $depth1;
}

.card_input .card_group--invalid {
  border-color: $red;
}

///////

// .card_input {
//   width: 100%;
//   margin-bottom: 50px;
// }

// .card_input .title {
//   margin: 0;
//   margin-bottom: 7px;
//   font-family: "Signika";
//   font-weight: 600;
//   font-size: 25px;
//   color: #000000;
// }

// .card_input .subtext {
//   margin: 0;
//   margin-bottom: 20px;
//   font-family: "Roboto Mono";
//   font-weight: 400;
//   font-size: 15px;
//   color: #a2a9b6;
// }

// .payment_bar .input {
//   //
// }

.card_input .donate_input {
  display: flex;
}
.card_input .donate_input.hide {
  display: none;
}

.card_input .donate_input .before_input {
  font-family: "Signika";
  font-weight: 600;
  font-size: 19px;
  margin: 0;
  color: $greytext;
  width: 55px;
  padding-top: 18px;
}

.card_input .donate_input .total {
  background: white;
  margin: 0 0 11px 11px;
  border: solid 1px black;
  border-radius: 4px;
  padding: 7px 0 0 10px;
  width: 120px;
  font-family: "Signika";
  font-weight: 600;
}

.card_input .donate_input .total .label {
  color: $lighttext;
  font-size: 14px;
  margin: 0 0 3px 0;
}

.card_input .donate_input .total .amount {
  color: $black;
  font-size: 18px;
  margin: 0;
}

.card_input .links {
  display: flex;
  justify-content: space-between;
  height: 71px;
  align-items: center;
}

.card_input .links div {
}

.card_input .links div.left {
}

.card_input .links div.left .hide {
  display: none;
}

.card_input .links div.left .label {
  font-family: "Signika";
  font-size: 19px;
  font-weight: 300;
  color: $black;
  padding: 0;
  margin: 0;
  user-select: none;
  cursor: default;
}

.card_input .links div.right {
}

.card_input .links div.right div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card_input .links div.right div svg {
  margin-bottom: 11px;
  margin-right: 11px;
}

.card_input .links div.right div svg.green {
  color: $green;
}

.card_input .links div.right div svg.red {
  color: $red;
}

.card_input .links div.right div div {
  min-width: 275px;
  width: 275px;
}

.card_input .links div.right .hide {
  display: none;
}

.card_input .links div button {
  font-family: "Signika";
  font-size: 19px;
  font-weight: 300;
  color: $greytext;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  user-select: none;
  cursor: pointer;
}

.card_input .links div button:hover {
  color: $black;
}

.card_input .links div button:active {
  color: $electric;
}
